html,
body {
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none !important;
  color: rgb(99, 112, 146);
}

h2 {
  color: #6c6c6c !important;
  margin-bottom: 2px;
}

h1 {
  color: #3fe8b5;
}

h4 {
  color: rgb(175, 175, 175);
}

h5 {
  color: #2b5597 !important;
}

.blue {
  color: #2b5597 !important;
}

.gray {
  color: #838383 !important;
}

.red {
  color: red !important;
}

.medium {
  font-size: 28px;
}

.right-pad {
  margin-right: 10px;
}

.left-pad {
  margin-left: 10px;
}

/* The side navigation menu */
.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  position: fixed;
  height: 100%;
  overflow: auto;
  box-shadow: 10px 10px 21px -10px rgba(0, 0, 0, 0.18);
}

.separator {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #7b8a94;
  color: #7b8a94;
  font-size: 15px;
}

.rdt_Table {
  max-height: 600px !important;
  overflow-y: auto !important;
}

.rdt_TableHead {
  background-color: #eef1f2;
  color: #3f494f;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
}

.sidebar,
.btn-primary {
  background-color: #3f494f;
  color: white !important;
  border: none;
}

.margin-left {
  margin-left: 10px;
}

.form-control {
  width: 300px;
  display: inline-block;
}

.rdt_TableHeadRow {
  background-color: transparent !important;
}

.rdt_TableBody .rdt_TableCell {
  cursor: pointer;
}

.home {
  display: block;
  padding: 100px;
  text-align: center;
}

.home .menu a {
  display: block;
  margin-top: 10px;
  padding: 10px;
  font-size: 25px;
  border-bottom: 1px solid #63737e;
}

.home .menu a:hover {
  background-color: #7a7a7a;
  color: white;
}

.home .menu a svg {
  margin-right: 10px;
}

/* Sidebar links */
.sidebar a svg {
  margin-right: 10px;
  font-size: 17px;
}

.sidebar a {
  display: block;
  color: white;
  padding: 16px;
  text-decoration: none;
  text-align: center;
  border-bottom: 1px solid rgb(112, 112, 112);
}

/* Active/current link */
.sidebar a.is-active {
  color: white;
  background-color: #8684b1;
}

.sidebar a:not(.is-active):hover {
  background-color: #7a7a7a;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 100%;
  min-height: 100vh;
  background-color: #e4ecf2;
}

.row {
  min-height: 300px;
}

.col,
.chart-col-75,
.chart-col-25,
.chart-col-33,
.chart-col-100,
.chart-container-75-25 {
  padding: 20px;
  margin: 8px;
  background-color: white;
  border-radius: 6px;
  text-align: center;
}

.chart-col {
  position: relative;
}

.col-small {
  max-width: 23%;
}

.col-medium {
  max-width: 46%;
}

.loading {
  padding: 50px;
  text-align: center;
  background-color: white;
  margin: 15px;
  background-color: white;
  border-radius: 6px;
}

.loader,
.error {
  height: 100%;
  width: 100%;
  text-align: center;
}

.error {
  margin-top: 50px;
  color: #2b5597 !important;
  font-size: 15px;
}

.error button {
  display: inline-block;
  width: 100px;
  margin-top: 20px;
}

.loader img {
  vertical-align: middle;
  opacity: 0.8;
}

.chartTitle {
  height: 60px;
  width: 100%;
  text-align: center;
  position: relative;
}

canvas {
  position: relative;
  z-index: 1;
}

.chartContainer {
  margin-top: 30px;
  width: 100%;
  height: 300px;
  max-height: 300px;
  position: relative;
}

.smaller-chartContainer .chartContainer {
  height: 180px;
}

.tiny-chartContainer .chartContainer {
  height: 100px;
}

.chartContainer {
  vertical-align: middle;
}

.chart-container .chartContainer,
.col-big .chartContainer,
.col-small .chartContainer,
.col-medium .chartContainer {
  margin-top: 0;
}

.chart-container .chartTitle,
.col-big .chartTitle,
.col-small .chartTitle,
.col-medium .chartTitle {
  height: 40px;
}

.chart-container h5,
.col-big .chartTitle h5,
.col-small .chartTitle h5,
.col-medium .chartTitle h5 {
  font-size: 14px;
  text-transform: uppercase;
}

.chart-container-75-25 {
  display: grid;
  grid-template-columns: 79% 20%;
  column-gap: 5px;
}

.chart-container-33 {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  column-gap: 1px;
}

.chart-col-75 {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  column-gap: 5px;
}

.chart-col-100 {
  display: grid;
  grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
  column-gap: 5px;
}

.full-cols {
  grid-template-columns: 100%;
  padding: 5px;
  margin: 0;
}

.chart-col-25 {
  display: grid;
  grid-template-columns: 100%;
  column-gap: 10px;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.background,
.background img {
  width: 100%;
  z-index: -5000;
  opacity: 0.3;
}

.content.absolute {
  background-color: transparent;
}

.reference p {
  padding: 10px 20px;
  margin-bottom: 2px;
  margin-right: 4px;
  display: inline-block;
  font-size: 12px;
}

.reference .col {
  margin: 0;
}

.custom-table tr {
  padding-bottom: 5px;
  border-bottom: 1 px solid gray;
}

.filter-container .css-b62m3t-container,
.filter-container .btn {
  display: inline-block;
  width: 100px;
  vertical-align: middle;
  margin: 10px;
}

.filter-container .css-b62m3t-container {
  width: 300px;
}

.form-table {
  width: 100%;
  margin: 0 auto;
  color: #656b70;
}

.form-table th {
  font-weight: normal;
  width: 15%;
}

.form-table thead tr {
  background-color: #eef1f2;
}

.title-like-table {
  margin: 0;
  padding: 5px;
  padding-top: 10px;
  background-color: rgb(230, 230, 230);
  line-height: 1;
  color: #656b70;
}

.form-table input[type="text"],
.form-table input[type="number"] {
  border: 1px solid #e7e8e9;
  border-radius: 3px;
  text-align: right;
  color: gray;
  width: 100%;
}

.form-table input[type="text"]:focus {
  border: 1px solid #d9dadb;
}

.form-table td {
  padding: 10px;
  width: 15%;
}

.progress-report {
  margin: 0 auto;
  margin-top: 30px;
  width: 1000px;
  font-size: 15px;
}

.progress-report table {
  width: 100%;
}

.progress-report h4 {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(170, 170, 170);
}

.progress-report,
.progress-report h4 {
  color: rgb(54, 54, 54);
}

.progress-report p {
  margin-bottom: 0;
  text-align: left;
}

.progress-report td {
  padding: 10px;
  color: #4b4b4b;
  text-align: left;
}

.progress-report .border {
  border: 1px solid rgb(170, 170, 170);
  border-radius: 3px;
}

.progress-report .nobottom {
  border-bottom: 0 !important;
}

.progress-report .notop {
  border-top: 0 !important;
}

.progress-report .col-big .chartTitle {
  height: auto;
}

.progress-report .col-big .chartTitle h5 {
  padding-bottom: 10px;
  margin-bottom: 2px;
  border-bottom: 1px solid #d1d1d1;
  color: #2b5597 !important;
}

.form-table {
  padding: 10px;
  border: 1px solid #dedede;
  border-top: 0;
}

.btn-light {
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #dedede;
}

.cs-title {
  margin-bottom: 18px;
  padding-bottom: 5px;
  border-bottom: 1px dotted white;
  width: 100%;
  display: block;
  grid-column: 1 / -1;
  color: #0134da;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: -10px;
  position: relative;
  font-size: 30px;
  text-align: center;
  width: 700px;
}

.cs-legend {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 10px;
  text-transform: none;
  color: rgb(199, 199, 199);
}

.cs-legend .square {
  height: 14px;
  width: 14px;
  border: 1px solid rgb(206, 206, 206);
  margin: 10px;
}

.cs-legend span {
  display: inline-block;
  vertical-align: middle;
}

.all-done {
  position: relative;
  height: 12px;
  margin-top: -5px;
  margin-left: 2px;
}

.all-done-white {
  width: 30px;
  position: absolute;
  z-index: 10000;
  bottom: 100px;
  left: calc(50% - 10px);
}

.borderless {
  border: 0;
}

.stats {
  vertical-align: middle;
  text-align: left;
  text-align: center;
}

.stats div {
  display: block;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid lightgray;
}

.stats h4 {
  font-size: 18px !important;
  display: inline-block;
  margin-right: 20px;
}

.stats h5 {
  font-size: 25px !important;
  display: inline-block;
}

.btn-refresh {
  border-color: #00000025;
  background-color: rgb(255, 255, 255);
  color: gray;
  font-size: 16px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 4px;
}

.btn-refresh:hover {
  color: white;
  background-color: grey;
}

.report-table {
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  margin-top: 10px;
}

.report-row {
  width: 100%;
  display: block;
  padding: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.349);
}

.report-col {
  display: inline-block;
  color: black;
  font-size: 14px !important;
}

.report-col a {
  color: rgb(44, 44, 44);
  font-weight: bold;
}

.report-header {
  margin-bottom: 3px;
  background-color: rgb(245, 245, 245);
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
}

thead,
tbody {
  width: 100%;
}

.dataTables_wrapper .dataTables_filter {
  float: left;
  text-align: left;
}
.dataTables_wrapper .dataTables_filter input {
  background-color: whitesmoke;
  margin-left: 20px;
  width: 650px;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 1100px) {
  .chartTitle h5 {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 800px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    z-index: 1000000;
  }
  .content {
    margin-top: 50px;
  }
  .filter-container {
    padding-top: 55px;
  }
  .sidebar a {
    float: left;
    font-size: 12px;
  }
  .separator {
    display: none;
  }
  .filter-container .css-b62m3t-container {
    width: 180px !important;
  }
  div.content {
    margin-left: 0;
  }
  .chart-container-75-25 {
    display: grid;
    grid-template-columns: 100%;
    column-gap: 5px;
  }

  .chart-container-33 {
    display: grid;
    grid-template-columns: 100%;
    column-gap: 1px;
  }

  .chart-col-75 {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 5px;
  }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 600px) {
  .sidebar a {
    float: left;
    font-size: 10px;
  }
  .filter-container .css-b62m3t-container {
    width: 120px !important;
  }
  .sidebar .svg-inline--fa {
    display: none;
  }
}
